$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

.bg-landing-page {
  //background-image: url("../../assets/images/Cognisphere_Transparent.png");
  .homepage-container {
    // align-items: center;
    position: relative;

    .video-tag {
      position: fixed;
      left: 0;
      top: 0;
      width: 30%;
      height: 100%;
    }

    .logged-in-user {
      padding-top: 200px;
      padding-right: 80px;
      color: var(--dark-blue);

      @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
        padding-right: 0px;
        padding-top: 0px;
      }

      .logged-in-user-heading {
        margin-bottom: 0;
        font-size: 32px;
        border-left: 8px solid var(--light-green);

        @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
          font-size: 32px;
          padding-right: 0px;
          text-align: left;
        }

        @media screen and (min-width: $mediumTablet) and (max-width: $largeTablet) {
          font-size: 32px;
          padding-right: 60px;
        }

        .logged-in-greetings {
          font-family: "SiemensSans-Bold", sans-serif;
          padding: 0 10px;
        }

        .logged-in-user-name {
          font-family: "SiemensSans-Black", sans-serif;
          text-transform: uppercase;
        }
      }

      .logged-in-project-desc {
        font-size: 24px;
        margin-bottom: 0;
      }
    }

    // .landing-page-menu {
    //     margin: 40px 0;

    //     .menu-item-container {
    //         position: relative;
    //         display: flex;
    //         align-items: center;
    //         background: var(--landing-page-card-bg-color);
    //         box-shadow: 0 0 10px var(--light-sand);
    //         border-radius: 8px;

    //         .menu-item-image-block {
    //             background: var(--white);
    //             margin: 10px;
    //             position: relative;
    //             left: -30px;
    //             border-radius: 10px;
    //             border: 1px solid var(--light-green);

    //             .menu-item-image {
    //                 height: 256px;
    //                 width: 200px;
    //                 // left: 40px;
    //             }
    //         }

    //         .menu-item-text-block {
    //             display: flex;
    //             // padding: 20px;
    //             span {
    //                 position: relative;
    //                 font-size: 16px;
    //             }
    //         }
    //     }
    // }

    .bannerImageCont {
      // // margin-top: 65px;
      // justify-content: center;
      // padding: 0px 20px 0px 20px;

      justify-content: center;
      // background: #0e1720;
      // background: #40576b;
      text-align: right;
      margin: 0px 15px 0px 15px;
      height: 300px;
      max-height: 300px;
      background-image: url("../../Assets/images/ui-images/home-banner-new-Copy.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .bannerImage {
      width: 61%;
      height: inherit;
    }

    .cardContainer {
      text-align: center;
      // padding: 20px 0px 20px 0px;
    }

    .cardImage {
      // margin-bottom: -5px;

      height: 180px;
      width: 180px;
    }

    .cardText {
      background: var(--master-blue);
      color: white;
      width: 100%;
      padding: 10px 0px 10px 0px;
      // margin-left: 20px;
      // margin-right: 20px;
    }

    .menuItemCont {
      // width: 264px;
      width: 100%;
    }

    .menuItemGrid {
      padding: 15px 15px 0px 15px;
    }

    .adminClick {
      //background-color: white;
      // height: 100px;
      // width: 100px;
      // position: absolute;
      // top: 80px;
      // right: 175px;
      // // border: 1px solid black;
      // padding: 10px;
      cursor: pointer;
    }

    .menu-item-image-block {
      background-color: var(--blue-green);
      border-radius: 10px 10px 0px 0px;
    }

    .bannerText {
      color: white;
      background-color: black;
    }

    .bannerImgRight {
      height: inherit;
      width: 600px;
      float: right;
      // margin-right: 25px;
    }
    .bannerTextLeftCont {
      height: inherit;
      float: left;
      color: white;

      font-family: "SiemensSans-Black", sans-serif;
      font-size: 1.5rem;
      line-height: 40px;
      // color: var(--dark-blue);
      margin: 0;

      @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
        font-size: 1.5rem;
        line-height: 30px;
      }
    }
    .bannerTextLeft {
      position: relative;
      border-left: 7px solid var(--light-green);
      margin-top: 21%;
      margin-left: 60px;
    }
    .bannerInnerText {
      font-size: 2rem;
      padding-left: 15px;
      text-align: left;
    }
    .bannerInnerTextFull {
      font-size: 1.5rem;
      font-family: "SiemensSans-Black", sans-serif;
      // font-size: 1.125rem;
    }
    .tagLineText {
      font-size: 0.9rem;
      text-align: left;
      margin-left: 85px;
      position: relative;
      top: 18%;
    }
  }
}

.inquiry-system-title {
  text-align: center;
  margin: 0px;
  font-family: "SiemensSans-Bold", sans-serif;
}

.custIconButton {
  .MuiSvgIcon-root {
    background-color: var(--petrol-green);
    color: black;
    margin-right: 5px;
  }
}

.custTabPanel {
  .MuiBox-root {
    padding: 0px;
  }
  .MuiGrid-item .ml-1 {
    margin-left: 0px;
  }
}

.custAppBar {
  .MuiTabs-flexContainer {
    justify-content: space-around;
  }
}

.inquiry-system-block {
  //   padding-bottom: 40px;

  .MuiSvgIcon-root {
    background: transparent !important;
  }

  .bulk-upload-sample-template {
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 365px) and (max-width: 1024px) {
      flex-direction: column;
    }

    .bulk-upload-form {
      .bulk-upload {
        margin-right: 24px;

        label {
          transform: translate(12px, 10px) scale(0.75);
        }
      }
    }
  }
}

.MuiStepLabel-label {
  margin-top: 0px !important;
}
.stepperLabel {
  font-family: "SiemensSans-Bold", sans-serif;
  font-size: 8px;
}
.inquiry-system {
  margin: 0px;
}

.footerButtonCont {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 0;
}

.MuiStepper-root {
  padding: 10px 0px 0px 0px !important;
}
.MuiStepLabel-active {
  text-shadow: 1px 1px lightgrey;
}
.MuiStepLabel-label.MuiStepLabel-active {
  font-weight: 650 !important;
  font-size: 18px;
}
.MuiStepIcon-active {
  border: 3px dashed #00646e;
}

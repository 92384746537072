.matTableClass {
  .MuiTableCell-root {
    padding: 7px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
  }
  .MuiTableRow-root {
    font-size: 13px;
  }
  width: 100%;
  .MuiButtonBase-root {
    font-size: 14px;
  }
}
.MuiToolbar-regular {
  min-height: 0px;
}
.MuiPaper-rounded {
  border: var(--light-green);
}
.matTableClass {
  .MuiIconButton-root {
    padding: 0px;
  }
  .MuiIconButton-root:hover {
    padding: 0px !important;
  }
  .MuiToolbar-regular {
    min-height: 35px;
  }
  .MuiTable-root {
    margin-bottom: 0px;
  }
}
.infeedFormDialog {
  .MuiDialogTitle-root {
    padding: 0px !important;
  }
}
.MuiDialog-paperScrollPaper {
  height: 95%;
  top: 3%;
}
.MuiDialog-paperWidthXl {
  width: 100%;
}

// Form related styles
.pt-divider {
  margin-top: 10px dashed #008aa6;
}
.dashed {
  border-width: 2px;
}

.cst-border-bottom {
  border-bottom: 2px dashed #008aa6;
  margin-top: 8px;
}
.cst-padding-top {
  padding-top: 1em;
}
hr.dashed {
  border-top: 2px dashed #008aa6;
  margin: 1.5em 0em 1.5em 0em;
}

.MuiMenu-paper {
  // margin-top: 3%;
}

#responsive-dialog-title {
  padding: 12px 16px;
}

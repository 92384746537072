.pt-divider {
  margin-top: 10px dashed #008aa6;
}
.dashed {
  border-width: 2px;
}

.cst-border-bottom {
  border-bottom: 2px dashed #008aa6;
  margin-top: 8px;
}
.cst-padding-top {
  padding-top: 1em;
}
hr.dashed {
  border-top: 2px dashed #008aa6;
  margin: 1.5em 0em 1.5em 0em;
}

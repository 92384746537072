@import "../src/Assets/fonts.css";
@import "../src/Assets/colors.css";

html,
body {
  padding: 0;
  margin: 0;
  background: var(--white);
  box-sizing: border-box;
  --scrollbarBG: var(--white);
  --thumbBG: var(--green-type-3);
}

/*Scrollbar*/
body::-webkit-scrollbar {
  width: 10px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "SiemensSans-Black", sans-serif;
  color: var(--dark-blue);
}

a {
  text-decoration: none;
  color: var(--dark-blue);
  display: block;
}

ul {
  background: var(--select-active-bg-color) !important;
}

li {
  color: var(--dark-blue) !important;
}

/*Display*/
.d-flex {
  display: flex;
}

/*Flex direction*/
.fd-col {
  flex-direction: column;
}

.fd-col-rev {
  flex-direction: column-reverse;
}

.fd-row-rev {
  flex-direction: row-reverse;
}

/*Flex position*/
.jc-flex-start {
  justify-content: flex-start;
}

.jc-flex-end {
  justify-content: flex-end;
}

/*Flex Wrap*/
.flex-wrap {
  flex-wrap: wrap;
}

/*Justify Content*/
.jc-center {
  justify-content: center;
}

.jc-space-bt {
  justify-content: space-between;
}

.jc-space-event {
  justify-content: space-between;
}

.jc-space-evenly {
  justify-content: space-evenly;
}

/*display*/
.d-block {
  display: block;
}

.align-items-center {
  align-items: center;
}

/* Z-index */
.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.z-6 {
  z-index: 6;
}

.z-7 {
  z-index: 7;
}

.z-8 {
  z-index: 8;
}

.z-9 {
  z-index: 9;
}

/* Paragraph and Span */
p {
  font-family: "SiemensSans-Bold", sans-serif;
}

span {
  font-family: "SiemensSans-Bold", sans-serif;
}

/* Text align */
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

/* Text Transform */
.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.official-logo {
  /* height: 24px; */
  width: 143px;
  margin: 0 16px 0 0;
}

.amps-master-block {
  background: var(--white);
}

.main-component {
  padding: 80px 40px 0px;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .main-component {
    padding: 80px 20px 20px;
  }
}

/* Input field with error */
.MuiFilledInput-root.Mui-error > input,
.MuiFilledInput-root.Mui-error > input:hover {
  background-color: var(--error-red-bg-color-hover) !important;
  color: var(--dark-blue);
}

.MuiFilledInput-underline.Mui-error::before,
.MuiFilledInput-underline.Mui-error::after {
  border-bottom: 2px solid var(--red) !important;
}

/* Table pagination icon */
.MuiTableSortLabel-root {
  text-align: center;
}

.MuiTablePagination-selectIcon {
  margin: -8px;
}

.MuiInputBase-input.Mui-disabled {
  cursor: no-drop;
}
.MuiAlert-action svg {
  font-size: 2.25rem !important;
  cursor: pointer;
}

.add-button {
  display: flex;
  justify-content: space-between;
}
.MuiSelect-select.Mui-disabled {
  cursor: no-drop;
}
.clr-white {
  li {
    color: var(--white);
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.policy-header {
  //text-decoration: underline;
  cursor: default;
  color: var(--blue-green);
}
.policy-header:hover {
  color: var(--blue-green);
}
.policy-icon {
  margin: -15px;
  font-size: 2.25rem;
}
.policy-icon:hover {
  color: var(--blue-green);
}

.adminMenu-item-container {
  position: relative;
  display: flex;
  background: var(--landing-page-card-bg-color);
  box-shadow: 0 0 10px var(--light-sand);
  border-radius: 8px;

  .adminMenu-item-text-block {
    padding: 20px;
  }
}

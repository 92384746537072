.inquiry-system-title {
  text-align: center;
  margin: 0px;
  font-family: "SiemensSans-Bold", sans-serif;
}
.componentHeaderGrid {
  .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .MuiFormControl-root.mt-1 {
    margin-top: 0px;
  }
  .componentTitle {
    display: flex;
    align-items: center;
  }
}

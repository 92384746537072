/*Font Family*/

/*Slab*/
@font-face {
    font-family: "SiemensSans-Black";
    src: url("./fonts/SiemensSans_Prof_Black.woff")
}

@font-face {
    font-family: "SiemensSans-BlackItalic";
    src: url("./fonts/SiemensSans_Prof_BlackItalic.woff")
}

@font-face {
    font-family: "SiemensSans-Bold";
    src: url("./fonts/SiemensSans_Prof_Bold.woff")
}

@font-face {
    font-family: "SiemensSans-BoldItalic";
    src: url("./fonts/SiemensSans_Prof_BoldItalic.woff")
}

@font-face {
    font-family: "SiemensSans-Italic";
    src: url("./fonts/SiemensSans_Prof_Italic.woff")
}

@font-face {
    font-family: "SiemensSans-Roman";
    src: url("./fonts/SiemensSans_Prof_Roman.woff")
}

/*Serif*/
@font-face {
    font-family: "SiemensSerif-Bold";
    src: url("./fonts/SiemensSerif_Prof_Bold.woff")
}

@font-face {
    font-family: "SiemensSerif-BoldItalic";
    src: url("./fonts/SiemensSerif_Prof_BoldItalic.woff")
}

@font-face {
    font-family: "SiemensSerif-Italic";
    src: url("./fonts/SiemensSerif_Prof_Italic.woff")
}

@font-face {
    font-family: "SiemensSerif-Roman";
    src: url("./fonts/SiemensSerif_Prof_Roman.woff")
}

@font-face {
    font-family: "SiemensSerif-Semibold";
    src: url("./fonts/SiemensSerif_Prof_Semibold.woff")
}

@font-face {
    font-family: "SiemensSerif-SemiboldItalic";
    src: url("./fonts/SiemensSerif_Prof_SemiboldItalic.woff")
}

/*Slab*/
@font-face {
    font-family: "SiemensSlab-Black";
    src: url("./fonts/SiemensSlab_Prof_Black.woff")
}

@font-face {
    font-family: "SiemensSlab-BlackItalic";
    src: url("./fonts/SiemensSlab_Prof_BlackItalic.woff")
}

@font-face {
    font-family: "SiemensSlab-Bold";
    src: url("./fonts/SiemensSlab_Prof_Bold.woff")
}

@font-face {
    font-family: "SiemensSlab-BoldItalic";
    src: url("./fonts/SiemensSlab_Prof_BoldItalic.woff")
}

@font-face {
    font-family: "SiemensSlab-Italic";
    src: url("./fonts/SiemensSlab_Prof_Italic.woff")
}

@font-face {
    font-family: "SiemensSlab-Roman";
    src: url("./fonts/SiemensSlab_Prof_Roman.woff")
}

/*Font Size*/
.f-8 {
    font-size: 0.5rem;
}

.f-10 {
    font-size: 0.625rem;
}

.f-12 {
    font-size: 0.75rem;
}

.f-14 {
    font-size: 0.875rem;
}

.f-16 {
    font-size: 1rem;
}

.f-18 {
    font-size: 1.125rem;
}

.f-20 {
    font-size: 1.25rem;
}

.f-22 {
    font-size: 1.375rem;
}

.f-24 {
    font-size: 1.5rem;
}

.f-26 {
    font-size: 1.625rem;
}

.f-28 {
    font-size: 1.75rem;
}

.f-30 {
    font-size: 1.875rem;
}

.f-32 {
    font-size: 2rem;
}

.f-34 {
    font-size: 2.125rem;
}

.f-36 {
    font-size: 2.25rem;
}

.f-38 {
    font-size: 2.375rem;
}

.f-40 {
    font-size: 2.5rem;
}

.f-60 {
    font-size: 3.75rem;
}

.f-72 {
    font-size: 4.5rem;
}

.f-80 {
    font-size: 5rem;
}

.f-100 {
    font-size: 6.25rem;
}